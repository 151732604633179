import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const GetStarted = () => {
  return <Nav to="/getstarted">GetStarted</Nav>;
};

export default GetStarted;

const Nav = styled(NavLink)``;
