import React from "react";
import styled from "styled-components";
import atFoot from "../assets/footAt.png";

const Footer = () => {
  return (
    <div>
      <Container>
        <Contain1>
          <Wrap1>
            <Logo src={atFoot} />
            <Items>
              <Heading>PRODUCTS</Heading>
              <Content>
                <List>Jira Software</List>
                <List>Jira Align</List>
                <List>Jira Service Management</List>
                <List>Confluence</List>
                <List>Trello</List>
                <List>Bitbucket</List>
              </Content>
              <End>View all products</End>
            </Items>
            <Items>
              <Heading>RESOURCES</Heading>
              <Content>
                <List>Technical Support</List>
                <List>Purchasing & licensing</List>
                <List>Atlassian Community</List>
                <List>Knowledge base</List>
                <List>Marketplace</List>
                <List>My Account</List>
              </Content>
              <End>Create support ticket</End>
            </Items>
            <Items>
              <Heading>EXPAND & LEARN</Heading>
              <Content>
                <List>Partners</List>
                <List>Training & Certification</List>
                <List>Documentation</List>
                <List>Developer Resources</List>
                <List>Enterprise services</List>
              </Content>
              <End>View all resources</End>
            </Items>
            <Items>
              <Heading>ABOUT ATLASSIAN</Heading>
              <Content>
                <List>Company</List>
                <List>Careers</List>
                <List>Events</List>
                <List>Blogs</List>
                <List>Investor Relations</List>
                <List>Trust & Security</List>
              </Content>
              <End>Contact us</End>
            </Items>
          </Wrap1>
        </Contain1>
        <Contain2>
          <Wrap2>
            <Left>
              <Footnavs>English</Footnavs>
              <Footnavs>Privacy policy</Footnavs>
              <Footnavs>Terms</Footnavs>
              <Footnavs>Modern Slavery Act</Footnavs>
              <Footnavs>Impressum</Footnavs>
            </Left>
            <Right></Right>
          </Wrap2>
        </Contain2>
      </Container>
    </div>
  );
};

export default Footer;

const Container = styled.div`
  width: 100%;
  height: 435px;
  display: flex;
  flex-direction: column;
  background-color: #f4f5f7;
  color: #42526e;
`;
const Contain1 = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Contain2 = styled.div`
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid silver;
`;
const Wrap1 = styled.div`
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Wrap2 = styled.div`
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Logo = styled.img`
  height: 30px;
  object-fit: cover;
`;
const Items = styled.div`
  height: 90%;
  display: flex;
  flex-direction: column;
`;
const Heading = styled.div`
  font-weight: bold;
  font-size: large;
  font-family: Charlie Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;
`;
const List = styled.div`
  font-size: 19px;
  font-family: Charlie Text, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  margin-top: 3px;
  margin-bottom: 3px;
  cursor: pointer;
  transition: all 350ms;
  :hover {
    text-decoration: underline;
  }
`;
const End = styled.div`
  color: blue;
  font-size: 19px;
  cursor: pointer;
  transition: all 350ms;
  :hover {
    text-decoration: underline;
  }
`;
const Left = styled.div`
  display: flex;
  align-items: center;
`;
const Right = styled.div``;
const Footnavs = styled.div`
  font-size: 19px;
  font-family: Charlie Text, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  margin-left: 30px;
  margin-right: 30px;
  cursor: pointer;
  transition: all 350ms;
  :hover {
    text-decoration: underline;
  }
`;
