import React, { useState } from "react";
import styled from "styled-components";

const Solution = () => {
  const [pop, setpop] = useState<boolean>(false);
  const dropDownish = () => {
    setpop(!pop);
  };
  const [pop2, setpop2] = useState<boolean>(false);
  const dropDownish2 = () => {
    setpop2(!pop2);
  };
  const [pop3, setpop3] = useState<boolean>(false);
  const dropDownish3 = () => {
    setpop3(!pop3);
  };
  return (
    <div>
      <Container>
        <Wrapper1>
          <Text>Atlassian solutions are designed for all types of work</Text>
          <ButtonWrap>
            <Button>Work Management</Button>
            <Button>IT Service Management</Button>
            <Button>Agile and DevOps</Button>
          </ButtonWrap>
        </Wrapper1>
        <Wrapper2></Wrapper2>
      </Container>
    </div>
  );
};

export default Solution;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Wrapper1 = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Wrapper2 = styled.div``;
const Text = styled.div`
  font-size: 44px;
  font-weight: 700;
  color: #253858;
  font-family: Charlie Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
`;
const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Button = styled.button`
  width: 420px;
  height: 110px;
  border: none;
  border-radius: 70px;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 10px 15px;
  font-weight: bold;
  font-size: x-large;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background-color: #deebff;
  color: #0052cc;
`;
