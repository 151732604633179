import React from "react";
import styled from "styled-components";
import letimg from "../../assets/career1.webp";
import riteimg from "../../assets/career2.webp";

const Careers = () => {
  return (
    <div>
      <Container>
        <Left src={letimg} />
        <Wrapper>
          <Bold>CAREERS</Bold>
          <Heading>We can’t do it alone</Heading>
          <Content>
            We have an ambitious road ahead, and we’re looking for people to
            join
            <br /> our global team to help shape the future of Atlassian.
          </Content>
          <Button>Join the team</Button>
        </Wrapper>
        <Right src={riteimg} />
      </Container>
    </div>
  );
};

export default Careers;
const Container = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
`;
const Left = styled.img`
  object-fit: cover;
  height: 100%;
  width: 25%;
`;
const Right = styled.img`
  object-fit: cover;
  height: 100%;
  width: 25%;
`;
const Wrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Bold = styled.div`
  font-weight: bolder;
  color: #008da6;
  font-size: x-large;
  font-family: Charlie Text, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
`;
const Heading = styled.div`
  font-size: 55px;
  color: #253858;

  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: Charlie Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
`;
const Content = styled.div`
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #253858;
  text-align: center;
  font-family: Charlie Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
`;
const Button = styled.div`
  width: 170px;
  height: 60px;
  background-color: #0052cc;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  transition: all 350ms;
  font-family: Charlie Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;

  cursor: pointer;
  :hover {
    background-color: #003c97;
  }
`;
