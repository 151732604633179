import React from "react";
import { useRoutes } from "react-router-dom";
import styled from "styled-components";
import AboutPage from "../Pages/AboutPage";
import ContactPage from "../Pages/ContactPage";
import DocsPage from "../Pages/DocsPage";
import GetStarted from "../Pages/GetStarted";
import HomePage from "../Pages/HomePage/HomePage";

const AllPages = () => {
  let element = useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/about",
      element: <AboutPage />,
    },
    {
      path: "/contact",
      element: <ContactPage />,
    },
    {
      path: "/docs",
      children: [
        {
          index: true,
          element: <DocsPage />,
        },
        {
          path: "getstted",
          element: <GetStarted />,
        },
      ],
    },
  ]);
  return element;
};

export default AllPages;
const Container = styled.div`
  width: 100%;
  height: 70px;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Nav = styled.div``;
