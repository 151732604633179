import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const DocsPage = () => {
  return <Nav to="getstarted">Getstarted</Nav>;
};

export default DocsPage;

const Nav = styled(NavLink)``;
