import React from "react";
import AllPages from "./Components/AllPages";
import Footer from "./Components/Footer";
import Head from "./Components/Head";

function App() {
  return (
    <div>
      <Head />
      <AllPages />
      <Footer />
    </div>
  );
}

export default App;
