import React from "react";
import Careers from "./Careers";
import Hero from "./Hero";
import Solution from "./Solution";
import Team from "./Team";

const HomePage = () => {
  return (
    <div>
      <Hero />
      <Solution />
      <Team />
      <Careers />
    </div>
  );
};

export default HomePage;
