import React from "react";
import styled from "styled-components";
import span from "../../assets/span2.webp";
import card2 from "../../assets/smartest003.webp";
import card3 from "../../assets/skyblu.webp";
import card4 from "../../assets/card3.webp";

const Team = () => {
  return (
    <div>
      <Container>
        <Wrap>
          <Cards>
            <Bold>TEAM ESSENTIALS</Bold>
            <Heading>
              Explore resources to
              <br /> unleash the potential
              <br /> of your team
            </Heading>
          </Cards>
          <Cards1>
            <Pic src={span} />
            <Comment>
              <First>Atlassian Community</First>
              <Second>
                Connect globally and meet locally to get more out of our
                products.
              </Second>
              <Third>
                <ThirdWrap>
                  <TextLeft>Search the forum</TextLeft>
                  <TextLeft>Join our community</TextLeft>
                </ThirdWrap>
              </Third>
            </Comment>
          </Cards1>
          <Cards2>
            <Pic2 src={card2} />
            <Comment2>
              <First2>Work Life</First2>
              <Second2>
                Real-life advice, inspiration, and stories
                <br /> from the working world today.
              </Second2>
              <Third2>
                <ThirdWrap2>
                  <TextLeft2>Search the forum</TextLeft2>
                </ThirdWrap2>
              </Third2>
            </Comment2>
          </Cards2>
          <Cards3>
            <Pic2 src={card3} />
            <Comment3>
              <First2>Atlassian Team Playbook</First2>
              <Second2>
                Solve common team challenges with
                <br /> these group exercises.
              </Second2>
              <Third2>
                <ThirdWrap2>
                  <TextLeft2>Check team health</TextLeft2>
                </ThirdWrap2>
              </Third2>
            </Comment3>
          </Cards3>
          <Cards4>
            <Pic2 src={card4} />
            <Comment4>
              <First2>Atlassian Team Playbook</First2>
              <Second2>
                Solve common team challenges with
                <br /> these group exercises.
              </Second2>
              <Third2>
                <ThirdWrap2>
                  <TextLeft2>Check team health</TextLeft2>
                </ThirdWrap2>
              </Third2>
            </Comment4>
          </Cards4>
          <Cards5></Cards5>
          <Cards6></Cards6>
          <Cards7></Cards7>
        </Wrap>
      </Container>
    </div>
  );
};

export default Team;

const Container = styled.div`
  width: 100%;
  height: 210vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrap = styled.div`
  width: 90%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 50px;
`;
const Cards = styled.div`
  display: flex;
  flex-direction: column;
`;
const Cards1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-column: span 2;
  border-radius: 8px;
  transition: all 350ms;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
`;
const Cards2 = styled.div`
  border-radius: 8px;
  transition: all 350ms;

  :hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
`;
const Cards3 = styled.div`
  border-radius: 8px;
  transition: all 350ms;

  :hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
`;
const Cards4 = styled.div`
  border-radius: 8px;
  transition: all 350ms;

  :hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
`;
const Cards5 = styled.div`
  background-color: blueviolet;
`;
const Cards6 = styled.div`
  background-color: bisque;
`;
const Cards7 = styled.div`
  background-color: #ff008c;
`;
const Bold = styled.div`
  font-weight: bolder;
  margin-top: 80px;
  color: #008da6;
  font-size: large;
  font-family: Charlie Text, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
`;
const Heading = styled.div`
  font-size: 45px;
  color: #253858;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Charlie Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
`;
const Pic = styled.img`
  width: 100%;
  height: 55%;
  object-fit: cover;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;
const Comment = styled.div`
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffebe5;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
const First = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: xx-large;
  color: #253858;
  font-weight: bold;
`;
const Second = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: x-large;
  color: #253858;
`;
const Third = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const ThirdWrap = styled.div`
  width: 55%;
  display: flex;
  justify-content: space-between;
`;

const TextLeft = styled.div`
  font-size: x-large;
  color: #1f32db;
  cursor: pointer;
`;

//wuenoiwnecoie
const Pic2 = styled.img`
  width: 100%;
  height: 57%;
  object-fit: cover;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;
const Comment2 = styled.div`
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ecdbff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
const Comment3 = styled.div`
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #dbf4ff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
const Comment4 = styled.div`
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #dbf4ff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
const First2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: xx-large;
  color: #253858;
  font-weight: bold;
`;
const Second2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: x-large;
  color: #253858;
`;
const Third2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const ThirdWrap2 = styled.div`
  width: 55%;
  display: flex;
  justify-content: center;
`;

const TextLeft2 = styled.div`
  font-size: x-large;
  color: #1f32db;
  cursor: pointer;
`;
