import React from "react";
import styled from "styled-components";
import back from "../../assets/smartest008.jpg";
import rightimg from "../../assets/smartest006.webp";

const Hero = () => {
  return (
    <div>
      <Container>
        <Wrapper>
          <Left>
            <TextWrap>
              <Text1>It’s possible</Text1>
              <Text2>with teamwork</Text2>
            </TextWrap>
          </Left>
          <Right src={rightimg} />
        </Wrapper>
      </Container>
    </div>
  );
};

export default Hero;

const Container = styled.div`
  width: 100%;
  display: flex;
`;
const Wrapper = styled.div`
  width: 95%;
  display: flex;
`;
const Left = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 70px;
  font-weight: 500;
  color: #253858;
  font-family: Charlie Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  background-image: url(${back});
`;
const Right = styled.img`
  width: 50%;
  height: 700px;
  object-fit: contain;
`;
const Text1 = styled.div``;
const TextWrap = styled.div``;
const Text2 = styled.div`
  margin-left: 70px;
`;
