import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/smartest007.webp";
import { HiOutlineChevronDown } from "react-icons/hi";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { CgProfile } from "react-icons/cg";

const Head = () => {
  return (
    <div>
      <Container>
        <Wrapper>
          <Left>
            <Logo src={logo} />
            <Nav>
              <Text>Products</Text>
              <DropArrow>
                <HiOutlineChevronDown />
              </DropArrow>
            </Nav>
            <Nav>
              <Text>For teams</Text>
              <DropArrow>
                <HiOutlineChevronDown />
              </DropArrow>
            </Nav>
            <Nav>
              <Text>Support</Text>
              <DropArrow>
                <HiOutlineChevronDown />
              </DropArrow>
            </Nav>
          </Left>
          <Right>
            <Button1>Try now</Button1>
            <Button2>Buy now</Button2>
            <Questionmark>
              <Innercircle>
                <HiMagnifyingGlass />
              </Innercircle>
            </Questionmark>
            <Nav2>
              <CgProfile />
              <Text2>My Account</Text2>
              <DropArrow>
                <HiOutlineChevronDown />
              </DropArrow>
            </Nav2>
          </Right>
        </Wrapper>
      </Container>
    </div>
  );
};

export default Head;
const Container = styled.div`
  width: 100%;
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Nav = styled.div`
  font-size: 20px;
  margin-left: 25px;
  width: 140px;
  height: 45px;
  color: #0052cc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all 350ms;
  cursor: pointer;
  :hover {
    background-color: #d3d3d3;
  }
`;
const Nav2 = styled.div`
  width: 170px;
  height: 45px;
  color: #0052cc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 20px;
  margin-left: 30px;
  transition: all 350ms;
  cursor: pointer;
  :hover {
    background-color: #d3d3d3;
  }
`;
const Text2 = styled.div`
  font-size: 21px;
  margin-left: 5px;
  color: #0052cc;
  font-family: Charlie Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
`;
const Wrapper = styled.div`
  width: 86%;
  display: flex;
  justify-content: space-between;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
`;
const Logo = styled.img`
  width: 260px;
  object-fit: contain;
`;
const Text = styled.div`
  font-size: 21px;
  color: #423939;
  font-family: Charlie Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
`;
const Button1 = styled.div`
  width: 110px;
  height: 45px;
  background-color: #0052cc;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: large;
  margin-left: 5px;
  margin-right: 5px;
  transition: all 350ms;
  font-family: Charlie Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;

  cursor: pointer;
  :hover {
    background-color: #003c97;
  }
`;
const Button2 = styled.div`
  width: 110px;
  height: 45px;
  color: #0052cc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: large;
  margin-left: 5px;
  margin-right: 5px;
  transition: all 350ms;
  font-family: Charlie Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;

  cursor: pointer;
  :hover {
    background-color: #e7e7e7;
  }
`;
const Questionmark = styled.div`
  width: 45px;
  height: 28px;
  display: flex;
  align-items: center;
  font-size: 29px;
  color: #aaaaaa;
  border-right: 3px solid silver;
`;
const DropArrow = styled.div`
  height: 10px;
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-left: 8px;
`;
const Innercircle = styled.div`
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 350ms;
  cursor: pointer;
  :hover {
    background-color: #d3d3d3;
  }
`;
